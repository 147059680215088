import { useNavigate, useParams } from '@solidjs/router';
import { onMount } from 'solid-js';

export default () => {
  const params = useParams<{ id: string; password: string }>();
  const navigate = useNavigate();

  onMount(() => {
    navigate(`/rental-applications/${params.id}/${params.password}/applicant-information`);
  });

  return null;
};
